import * as React from 'react';
import { graphql } from 'gatsby';
import { FixedObject, FluidObject } from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import cn from 'classnames';
import { Language } from '../data/languages';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { models } from '../../models';

type Props = {
  data: {
    contentfulHome: {
      title: string;
      backgroundImages: {
        fluid: FluidObject;
      }[];
      node_locale: Language;
      seoText: { seoText: string };
      featuredImage: { fixed: FixedObject };
    };
  };
  location: Location;
};

function Home({
  data: {
    contentfulHome: {
      featuredImage,
      seoText,
      title,
      backgroundImages,
      node_locale,
    },
  },
  location,
}: Props) {
  const randomImageIndex = Math.floor(
    Math.random() * Math.floor(backgroundImages.length - 1)
  );
  return (
    <Layout
      lang={node_locale}
      location={location}
      pathnameWithoutLang={models.dynamic.home.path}
    >
      <SEO
        featuredImage={featuredImage?.fixed?.src}
        title={title}
        description={seoText.seoText}
        lang={node_locale}
      />
      <BackgroundImage
        Tag="span"
        className={cn({ 'top-bg': true, active: true })}
        fluid={backgroundImages[randomImageIndex].fluid}
        fadeIn={true}
      />
      <span className={'top-bg-overlay'} />
    </Layout>
  );
}

export default Home;

export const pageQuery = graphql`
  query homeQuery($id: String!) {
    contentfulHome(id: { eq: $id }) {
      seoText {
        seoText
      }
      featuredImage {
        fixed(width: 1200) {
          src
          srcSet
        }
      }
      backgroundImages {
        fluid(maxWidth: 1600) {
          aspectRatio
          sizes
          src
          srcSet
        }
      }
      node_locale
    }
  }
`;
